





































import {
  defineComponent,
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
} from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  AtomLoading,
  MolGuideLink,
  MolGuideLinkArticleEnum,
  OrgHeaderInfo,
} from "@/v2/new-design-system";
import { fetchBusiness } from "@/v2/repo/business-settings";
import { IBusinessSettingsRouteEnum } from "../business-settings-route.enum";
import { watchFlag, unWatchFlag } from "@/v2/core/feature-flag";
import { VIEW_PAYOUT_PAYEE } from "@/utils/enums/permissions";
import { useStore } from "@/store";
import { report } from "@chatfood/bug-reporter";
import { t } from "@/i18n";

const css = bemBuilder("business-settings-main");

export default defineComponent({
  name: "BusinessSettingsMain",
  components: {
    AtomLoading,
    MolGuideLink,
    OrgHeaderInfo,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(true);

    const businessName = ref("");
    const businessWebsite = ref("");
    const businessOrderingLanguage = ref("");
    const businessTypes = ref<Array<{ label: string; value: string }>>([]);
    const businessType = ref("");
    const businessLogo = ref<string | null>(null);
    const businessCuisines = ref<Array<string>>([]);
    const businessOrderingLink = ref("");
    const businessCountry = ref("");

    const propsView = computed(() => {
      return {
        businessName: businessName.value,
        businessWebsite: businessWebsite.value,
        businessOrderingLanguage: businessOrderingLanguage.value,
        businessTypes: businessTypes.value,
        businessType: businessType.value,
        businessLogo: businessLogo.value,
        businessCuisines: businessCuisines.value,
        businessOrderingLink: businessOrderingLink.value,
        isFromUae: businessCountry.value === "AE",
      };
    });

    onMounted(() => {
      getBusinessSettings();
    });

    async function getBusinessSettings() {
      isLoading.value = true;

      try {
        const {
          name,
          language,
          website,
          category,
          cuisines,
          logo,
          orderingLink,
          country,
        } = await fetchBusiness(props.businessId);
        const [type] = businessTypes.value;

        businessName.value = name;
        businessWebsite.value = website;
        businessOrderingLanguage.value = language;
        businessType.value = category || type.value;
        businessCuisines.value = cuisines.map(({ id }: { id: string }) => id);
        businessLogo.value = logo;
        businessOrderingLink.value = orderingLink;
        businessCountry.value = country;
      } catch (e: any) {
        const { error = {} } = e;
        report(e, {
          context: {
            error: e,
          },
          tags: {
            "http.response_code": error.status,
          },
        });
      } finally {
        isLoading.value = false;
      }
    }

    const { getters } = useStore();

    const showPayoutsView = ref(false);
    const payoutsFlagName = "payouts-business-bank-account";

    const updateFlagHandler = (newValue: boolean) => {
      const permissions = getters["auth/getPermissions"];
      showPayoutsView.value =
        newValue && permissions.includes(VIEW_PAYOUT_PAYEE);
    };

    watchFlag(payoutsFlagName, updateFlagHandler);

    onBeforeUnmount(() => {
      unWatchFlag(payoutsFlagName, updateFlagHandler);
    });

    return {
      t,
      css,
      isLoading,
      propsView,
      showPayoutsView,
      MolGuideLinkArticleEnum,
      IBusinessSettingsRouteEnum,
    };
  },
});
