export const BusinessSettingsUaeBankList = [
  {
    id: "ABNB",
    name: "ABN Amro Bank N.V.",
  },
  {
    id: "ADCB",
    name: "Abu Dhabi Commercial Bank PLC",
  },
  {
    id: "ADIB",
    name: "Abu Dhabi Islamic Bank PLC",
  },
  {
    id: "AJBK",
    name: "Ajman Bank",
  },
  {
    id: "AABK",
    name: "Al Ahli Bank of Kuwait KSC",
  },
  {
    id: "ALAI",
    name: "Al Ain Finance",
  },
  {
    id: "HLAL",
    name: "Al Hilal Bank",
  },
  {
    id: "LICO",
    name: "Al Khaliji France S.A.",
  },
  {
    id: "ABIN",
    name: "Al Masraf (Arab Bank for Investment & Foreign Trade)",
  },
  {
    id: "AAIB",
    name: "Arab African International Bank",
  },
  {
    id: "ARBK",
    name: "Arab Bank PLC",
  },
  {
    id: "AEIN",
    name: "Arab Emirates Investment Bank",
  },
  {
    id: "ALFH",
    name: "Bank Alfalah Limited",
  },
  {
    id: "BAMI",
    name: "Bank Melli Iran",
  },
  {
    id: "BOBA",
    name: "Bank of Baroda",
  },
  {
    id: "BOSH",
    name: "Bank of Sharjah",
  },
  {
    id: "BASI",
    name: "Bank Saderat Iran",
  },
  {
    id: "CABA",
    name: "Banque Du Caire",
  },
  {
    id: "BLLC",
    name: "Banque Libanaise Pour Le Commerce",
  },
  {
    id: "BCAI",
    name: "BANQUE MISR",
  },
  {
    id: "BABP",
    name: "Barclays Bank PLC",
  },
  {
    id: "BABA",
    name: "Blom Bank France",
  },
  {
    id: "BNPP",
    name: "BNP Paribas",
  },
  {
    id: "CIBA",
    name: "CitiBank N.A.",
  },
  {
    id: "COBI",
    name: "Commercial Bank International",
  },
  {
    id: "COBD",
    name: "Commercial Bank Of Dubai PSC",
  },
  {
    id: "CAGI",
    name: "Credit Agricole Indosuez",
  },
  {
    id: "DEUT",
    name: "Deutsche Bank",
  },
  {
    id: "DOHA",
    name: "Doha Bank",
  },
  {
    id: "DUIB",
    name: "Dubai Islamic Bank PLC",
  },
  {
    id: "EINB",
    name: "El Nillien Bank",
  },
  {
    id: "EMDV",
    name: "Emirates Development Bank",
  },
  {
    id: "MEB",
    name: "Emirates Islamic (Dubai Bank)",
  },
  {
    id: "EBI",
    name: "Emirates NBD PJSC",
  },
  {
    id: "FIHO",
    name: "Finance House PJSC",
  },
  {
    id: "NBAD",
    name: "First Abu Dhabi Bank",
  },
  {
    id: "FIGB",
    name: "First Gulf Bank PLC",
  },
  {
    id: "GULF",
    name: "GULF INTERNATIONAL BANK B.S.C.",
  },
  {
    id: "HBAZ",
    name: "Habib Bank AG Zurich",
  },
  {
    id: "HABL",
    name: "Habib Bank Ltd",
  },
  {
    id: "HSBC",
    name: "HSBC Bank Middle East",
  },
  {
    id: "ICBK",
    name: "Industrial and Commercial Bank of China",
  },
  {
    id: "BCIT",
    name: "INTESA SANPAOLO",
  },
  {
    id: "INBA",
    name: "Invest Bank PLC",
  },
  {
    id: "JANB",
    name: "Janata Bank",
  },
  {
    id: "KOEX",
    name: "Korean Exchange Bank",
  },
  {
    id: "MASB",
    name: "Mashreq Bank PSC",
  },
  {
    id: "NBOB",
    name: "National Bank of Bahrain BSC",
  },
  {
    id: "NBFU",
    name: "National Bank of Fujairah PSC",
  },
  {
    id: "NBOK",
    name: "NATIONAL BANK OF KUWAIT (S.A.K)",
  },
  {
    id: "NBOM",
    name: "National Bank of Oman Ltd. (SAOG)",
  },
  {
    id: "NBUQ",
    name: "National Bank of Umm-Al-Qaiwain PSC",
  },
  {
    id: "NOIB",
    name: "Noor Islamic Bank",
  },
  {
    id: "RAFB",
    name: "Rafidain Bank",
  },
  {
    id: "RAKB",
    name: "RAK Bank - The National Bank of RAK PSC",
  },
  {
    id: "SAMB",
    name: "Samba Financial Group",
  },
  {
    id: "NOAS",
    name: "Sharjah Islamic Bank",
  },
  {
    id: "STCB",
    name: "Standard Chartered Bank",
  },
  {
    id: "BOTK",
    name: "The Bank of Tokyo-Mitsubishi UFJ Ltd",
  },
  {
    id: "UNNB",
    name: "Union National Bank PLC",
  },
  {
    id: "UNAB",
    name: "United Arab Bank PLC",
  },
  {
    id: "UNBL",
    name: "United Bank Limited",
  },
  {
    id: "WIOB",
    name: "Wio Bank P.J.S.C",
  },
];
