




















































































































































































import i18n, { t } from "@/i18n";
import {
  computed,
  defineComponent,
  getCurrentInstance,
  PropType,
  onMounted,
  ref,
  watch,
} from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  fetchBusinessCategories,
  fetchBusinessCuisines,
  saveBusinessSettings,
  IFetchBusinessCategory,
  IFetchBusinessCuisine,
  uploadBusinessLogo,
  removeBusinessLogo,
} from "@/v2/repo/business-settings";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomLoading,
  AtomButton,
  AtomSelect,
  MolInfoBulb,
  MolSearchBox,
  MolUploadImage,
  MolFormControl,
  MolClipboardCopy,
  OrgSelectList,
  OrgFormInput,
} from "@/v2/new-design-system";
import { Toast } from "@/design-system";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { ORDERING_LANGUAGES } from "@/v2/module/business-settings/domain/ordering-languages";
import { OrgImageCropper } from "@/v2/design-system/org/image-cropper";
import { validateImage, ValidateImageEnum } from "@/v2/util/validate-image";
import { imageValidationErrorToast } from "@/v2/util/image-validation-error-toast";
import { businessSettingsTrack } from "@/v2/module/business-settings/track";
import { BusinessTypeEnum } from "@/v2/repo/business-settings/business_type.enum";
import { EventEnum } from "@/v2/enum";
import { report } from "@chatfood/bug-reporter";

const css = bemBuilder("business-settings-general-settings");

export default defineComponent({
  name: "BusinessSettingsGeneralSettings",
  components: {
    AtomText,
    AtomLoading,
    AtomSelect,
    AtomButton,
    MolInfoBulb,
    MolFormControl,
    MolClipboardCopy,
    MolSearchBox,
    MolUploadImage,
    OrgFormInput,
    OrgSelectList,
    OrgImageCropper,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    businessName: {
      type: String,
      default: "",
    },
    businessWebsite: {
      type: String,
      default: "",
    },
    businessOrderingLanguage: {
      type: String,
      default: "",
    },
    businessTypes: {
      type: Array as PropType<Array<{ label: string; value: string }>>,
      default: () => [],
    },
    businessType: {
      type: String,
      default: "",
    },
    businessLogo: {
      type: String,
      default: null,
    },
    businessCuisines: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
    businessOrderingLink: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const vm = getCurrentInstance();
    const isLoading = ref(false);
    const isSaving = ref(false);
    const isLogoSaving = ref(false);

    const businessNameRef = ref(props.businessName);
    const businessWebsiteRef = ref(props.businessWebsite);
    const businessOrderingLanguageRef = ref(props.businessOrderingLanguage);
    const businessTypeRef = ref(props.businessType);
    const businessLogoRef = ref<string | null>(props.businessLogo);
    const businessCuisinesRef = ref<Array<string>>(props.businessCuisines);
    const businessTypesRef = ref<Array<{ label: string; value: string }>>(
      props.businessTypes
    );
    const businessOrderingLinkRef = ref(props.businessOrderingLink);

    const allCuisines = ref<Array<IFetchBusinessCuisine>>([]);
    const searchQuery = ref("");
    const iconImageUpload = ref<string | null>(null);
    const orderingLanguages = ref(ORDERING_LANGUAGES);
    const errors = ref<Record<string, string>>({});

    const imageCropperConfig = ref<{ width: number; height: number }>({
      width: 200,
      height: 200,
    });

    const orderingLink = computed(() => {
      return {
        link: businessOrderingLinkRef.value,
        text: businessOrderingLinkRef.value.replace(
          /^(https:\/\/)?(http:\/\/)?/i,
          ""
        ),
      };
    });

    const mapBusinessTypes = computed(() => ({
      [BusinessTypeEnum.RESTAURANT]: t(
        "module.business_settings.business_type_restaurant"
      ),
      [BusinessTypeEnum.BAKERY]: t(
        "module.business_settings.business_type_bakery"
      ),
      [BusinessTypeEnum.BUTCHER_SHOP]: t(
        "module.business_settings.business_type_butchery"
      ),
      [BusinessTypeEnum.DESSERT_SHOP]: t(
        "module.business_settings.business_type_dessert"
      ),
      [BusinessTypeEnum.GROCERY]: t(
        "module.business_settings.business_type_grocery"
      ),
      [BusinessTypeEnum.MEAL_PLANS]: t(
        "module.business_settings.business_type_meal"
      ),
      [BusinessTypeEnum.FLORIST]: t(
        "module.business_settings.business_type_florist"
      ),
      [BusinessTypeEnum.RETAIL]: t(
        "module.business_settings.business_type_retail"
      ),
      [BusinessTypeEnum.CINEMA]: t(
        "module.business_settings.business_type_cinema"
      ),
      [BusinessTypeEnum.ROOM]: t("module.business_settings.business_type_room"),
      [BusinessTypeEnum.BAR]: t("module.business_settings.business_type_bar"),
      [BusinessTypeEnum.CATERING]: t(
        "module.business_settings.business_type_catering"
      ),
      [BusinessTypeEnum.BEACH_CLUB]: t(
        "module.business_settings.business_type_beach_club"
      ),
      [BusinessTypeEnum.HOTEL]: t(
        "module.business_settings.business_type_beach_hotel"
      ),
      [BusinessTypeEnum.OTHERS]: t(
        "module.business_settings.business_type_others"
      ),
    }));

    async function getBusinessCategories() {
      try {
        const result = await fetchBusinessCategories();

        businessTypesRef.value = result.map(
          (category: IFetchBusinessCategory) => ({
            label: category.name,
            value: category.slug,
          })
        );
      } catch (e: any) {
        const { error = {} } = e;
        report(e, {
          context: {
            error,
          },
          tags: {
            "http.response_code": error.status,
          },
        });
      }
    }

    async function getBusinessCuisines(language: string = i18n.locale) {
      try {
        allCuisines.value = await fetchBusinessCuisines(language);
      } catch (e: any) {
        const { error = {} } = e;
        report(e, {
          context: {
            error,
          },
          tags: {
            "http.response_code": error.status,
          },
        });
      }
    }

    watch(
      () => i18n.locale,
      async (locale) => {
        await getBusinessCuisines(locale);
      }
    );

    onMounted(async () => {
      isLoading.value = true;
      try {
        await Promise.all([getBusinessCategories(), getBusinessCuisines()]);
      } finally {
        isLoading.value = false;
      }
    });

    async function onSelectImage(imageFile: File) {
      const imageValidationConfig = {
        maxFileSizeMB: 10,
        minWidth: imageCropperConfig.value.width,
        minHeight: imageCropperConfig.value.height,
      };

      const imageValidation = await validateImage(
        imageFile,
        imageValidationConfig
      );

      imageValidationErrorToast(imageValidation, imageValidationConfig);

      if (imageValidation === ValidateImageEnum.VALID) {
        iconImageUpload.value = URL.createObjectURL(imageFile);
      }
    }

    async function uploadImage(file: File & { lastModifiedDate: Date }) {
      isLogoSaving.value = true;
      try {
        iconImageUpload.value = null;
        file.lastModifiedDate = new Date();
        businessLogoRef.value = await uploadBusinessLogo(
          props.businessId,
          file
        );
        await vm?.proxy.$store.dispatch("businesses/fetch");
      } catch (e: any) {
        const { error = {} } = e;
        report(e, {
          context: {
            error,
          },
          tags: {
            "http.response_code": error.status,
          },
        });
      } finally {
        isLogoSaving.value = false;
      }
    }

    async function removeLogo() {
      isLogoSaving.value = true;
      try {
        await removeBusinessLogo(props.businessId);
        await vm?.proxy.$store.dispatch("businesses/fetch");
        businessLogoRef.value = null;
      } catch (e: any) {
        const { error = {} } = e;
        report(e, {
          context: {
            error,
          },
          tags: {
            "http.response_code": error.status,
          },
        });
      } finally {
        isLogoSaving.value = false;
        handleTrack(EventEnum.BRAND_SETTING_LOGO_REMOVE);
      }
    }

    function validateForm() {
      let messages: Record<string, string> = {};
      if (businessNameRef.value.trim().length < 3) {
        messages.businessName = t(
          "module.business_settings.business_name_error"
        );
      }
      errors.value = messages;
    }

    const onSaveSettings = async () => {
      validateForm();

      if (Object.keys(errors.value).length) return;

      isSaving.value = true;

      try {
        await saveBusinessSettings(props.businessId, {
          name: businessNameRef.value.trim(),
          website: businessWebsiteRef.value.trim(),
          category: businessTypeRef.value,
          language: businessOrderingLanguageRef.value,
          cuisineIds:
            businessTypeRef.value === BusinessTypeEnum.RESTAURANT
              ? businessCuisinesRef.value
              : [],
        });

        vm?.proxy.$store.dispatch("businesses/fetch");

        new Toast().create({
          type: "success",
          title: t("label.success"),
          text: t("module.business_settings.business_setting_saved"),
        });
      } catch (e: any) {
        const { error = {} } = e;

        if (error.validationError && error.validationError.website) {
          errors.value = {
            ...errors.value,
            businessWebsite: error.validationError.website,
          };
          return;
        }

        generalErrorToast(e);

        report(e, {
          context: {
            error,
          },
          tags: {
            "http.response_code": error.status,
          },
        });
      } finally {
        isSaving.value = false;
        handleTrack(EventEnum.BRAND_SETTING_SAVE);
      }
    };

    const handleTrack = (eventName: EventEnum) => {
      businessSettingsTrack({
        // @ts-ignore
        analytics: vm?.proxy.$analytics,
        eventName,
        businessId: props.businessId,
      });
    };

    const onCuisinesChange = (ids: Array<string>) => {
      businessCuisinesRef.value = ids;
      handleTrack(EventEnum.BRAND_SETTING_CUISINE);
    };

    return {
      t,
      css,
      businessNameRef,
      businessWebsiteRef,
      businessOrderingLanguageRef,
      businessTypeRef,
      businessLogoRef,
      businessTypesRef,
      businessCuisinesRef,
      mapBusinessTypes,
      errors,
      orderingLink,
      orderingLanguages,
      isLoading,
      isSaving,
      isLogoSaving,
      allCuisines,
      searchQuery,
      imageCropperConfig,
      iconImageUpload,
      onSaveSettings,
      onSelectImage,
      uploadImage,
      removeLogo,
      handleTrack,
      onCuisinesChange,
      EventEnum,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      BusinessTypeEnum,
    };
  },
});
