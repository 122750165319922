export const ORDERING_LANGUAGES = [
  { label: "English", value: "en-US" },
  { label: "Español (España)", value: "es-AR" },
  { label: "Español", value: "es" },
  { label: "Français (France)", value: "fr" },
  { label: "Italiano", value: "it" },
  { label: "Português (Brasil)", value: "pt-BR" },
  { label: "Türkçe", value: "tr-TR" },
  { label: "العربية", value: "ar" },
];
