

































































































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  ref,
  reactive,
  computed,
  watch,
  onMounted,
} from "@vue/composition-api";
import {
  AtomButton,
  AtomLoading,
  MolMultiselect,
  MolFormControl,
  OrgFormInput,
} from "@/v2/new-design-system";
import { Toast } from "@/design-system";
import { BusinessSettingsUaeBankList } from "./UaeBankList";
import { formValidation, removeSpecialCharacters } from "@chatfood/core-utils";
import {
  fetchBusinessPayoutPayee,
  IFetchBusinessPayoutPayee,
  updateBusinessPayoutPayee,
} from "@/v2/repo/business-settings";
import { t } from "@/i18n";

const css = bemBuilder("business-settings-payouts");

type IBank = {
  id: string;
  name: string;
};

export default defineComponent({
  name: "BusinessSettingsPayouts",
  components: {
    AtomButton,
    AtomLoading,
    MolMultiselect,
    MolFormControl,
    OrgFormInput,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    isFromUae: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const isLoading = ref(true);
    const isSaving = ref(false);

    const formValues: Record<string, string> = reactive({
      taxId: "",
      accountHolderName: "",
      iban: props.isFromUae ? "AE" : "",
      swiftCode: "",
      accountNumber: "",
      bankCode: "",
      bankName: "",
    });

    const selectedBank = computed(() => {
      if (formValues.bankCode && formValues.bankName) {
        return [
          {
            id: formValues.bankCode,
            name: formValues.bankName,
          },
        ];
      }
      return [];
    });

    function onSelectBank(bank: IBank) {
      formValues.bankCode = bank.id;
      formValues.bankName = bank.name;
    }

    function onChange(
      key:
        | "accountHolderName"
        | "iban"
        | "swiftCode"
        | "accountNumber"
        | "bankCode"
        | "bankName"
        | "taxId",
      value: string
    ) {
      formValues[key] = value.trim();
    }

    const listenFormValues = computed(() => {
      return Object.assign({}, formValues);
    });

    watch(
      () => listenFormValues.value,
      (newValue, oldValue) => {
        if (newValue.iban || newValue.accountNumber) {
          if (props.isFromUae && !newValue.iban.startsWith("AE")) {
            formValues.iban = `AE${newValue.iban}`;
          } else {
            ["iban", "accountNumber"].forEach((item) => {
              if (newValue[item] !== oldValue[item]) {
                formValues[item] = removeSpecialCharacters(
                  newValue[item]
                ).replace(/\s/g, "");
              }
            });
          }
        }

        if (
          newValue.accountHolderName ||
          (!props.isFromUae && (newValue.bankCode || newValue.bankName))
        ) {
          ["accountHolderName", "bankCode", "bankName"].forEach((item) => {
            if (newValue[item] !== oldValue[item]) {
              formValues[item] = removeSpecialCharacters(newValue[item]);
            }
          });
        }
      }
    );

    onMounted(() => {
      getBusinessPayout();
    });

    async function getBusinessPayout() {
      try {
        isLoading.value = true;
        const response = await fetchBusinessPayoutPayee(props.businessId);

        if (response) {
          formValues.taxId = response.taxId;

          const bankAccount = response.bankAccount;

          if (bankAccount) {
            formValues.accountHolderName = bankAccount.accountHolderName;
            formValues.iban = bankAccount.iban;
            formValues.swiftCode = bankAccount.swiftCode;
            formValues.accountNumber = bankAccount.accountNumber;
            formValues.bankCode = bankAccount.bankCode;
            formValues.bankName = bankAccount.bankName;
          }
        }
      } catch {
        new Toast().create({
          type: "error",
          text: t("module.business_settings.payouts.record_failure"),
        });
      } finally {
        isLoading.value = false;
      }
    }

    const errors = ref({});

    const requiredError = "module.business_settings.payouts.required_error";
    const accountHolderNameLabel =
      "module.business_settings.payouts.account_holder_name";
    const ibanAccountNumberLabel =
      "module.business_settings.payouts.iban_account_number";
    const bankCodeLabel = "module.business_settings.payouts.bank_code";
    const bankNameLabel = "module.business_settings.payouts.bank_name";
    const accountNumberLabel =
      "module.business_settings.payouts.account_number";
    const maxLengthError = "module.business_settings.payouts.max_length_error";

    function isValid() {
      const formConfigUae = {
        accountHolderName: {
          required: {
            message: t(requiredError, {
              field: t(accountHolderNameLabel),
            }),
          },
          maxLength: {
            limit: 100,
            message: t(maxLengthError, {
              field: t(accountHolderNameLabel),
              max: 100,
            }),
          },
        },
        iban: {
          required: {
            message: t(requiredError, {
              field: t(ibanAccountNumberLabel),
            }),
          },
          minLength: {
            limit: props.isFromUae ? 23 : null,
            message: t("module.business_settings.payouts.length_error", {
              field: t(ibanAccountNumberLabel),
              number: 23,
            }),
          },
          maxLength: {
            limit: props.isFromUae ? 23 : 34,
            message: t(maxLengthError, {
              field: t(ibanAccountNumberLabel),
              max: props.isFromUae ? 23 : 34,
            }),
          },
        },
        bankCode: {
          required: {
            message: t(requiredError, {
              field: t(bankCodeLabel),
            }),
          },
          maxLength: {
            limit: 20,
            message: t(maxLengthError, {
              field: t(bankCodeLabel),
              max: 20,
            }),
          },
        },
        bankName: {
          required: {
            message: t(requiredError, {
              field: t(bankNameLabel),
            }),
          },
          maxLength: {
            limit: 100,
            message: t(maxLengthError, {
              field: t(bankNameLabel),
              max: 100,
            }),
          },
        },
        taxId: {
          maxLength: {
            limit: 100,
            message: t(maxLengthError, {
              field: t(
                "module.business_settings.payouts.tax_registration_number"
              ),
              max: 100,
            }),
          },
        },
      };

      const formConfigNonUae = {
        ...formConfigUae,
        swiftCode: {
          required: {
            message: t(requiredError, {
              field: t("module.business_settings.payouts.swift_code"),
            }),
          },
        },
        accountNumber: {
          required: {
            message: t(requiredError, {
              field: t(accountNumberLabel),
            }),
          },
          maxLength: {
            limit: 50,
            message: t(maxLengthError, {
              field: t(accountNumberLabel),
              max: 50,
            }),
          },
        },
      };

      errors.value = formValidation(
        props.isFromUae ? formConfigUae : formConfigNonUae,
        formValues
      );

      return Boolean(!Object.keys(errors.value).length);
    }

    async function onSave() {
      try {
        isSaving.value = true;
        if (!isValid()) return;

        const payload: IFetchBusinessPayoutPayee = {
          taxId: formValues.taxId,
          bankAccount: {
            accountHolderName: formValues.accountHolderName,
            iban: formValues.iban,
            swiftCode: formValues.swiftCode,
            accountNumber: formValues.accountNumber,
            bankCode: formValues.bankCode,
            bankName: formValues.bankName,
          },
        };

        await updateBusinessPayoutPayee({
          businessId: props.businessId,
          payload,
        });

        new Toast().create({
          type: "success",
          text: t("module.business_settings.payouts.record_updated"),
        });
      } catch {
        new Toast().create({
          type: "error",
          text: t("module.business_settings.payouts.record_failure"),
        });
      } finally {
        isSaving.value = false;
      }
    }

    return {
      t,
      css,
      onChange,
      formValues,
      onSelectBank,
      selectedBank,
      isSaving,
      onSave,
      errors,
      isLoading,
      BusinessSettingsUaeBankList,
    };
  },
});
